import React from 'react';
import './SessionBar.css';
import {send} from 'lib/socket';
import TimeValue from './TimeValue';
import TimeBar from './TimeBar';
import moment from 'moment';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';
import {getData} from 'lib/data';

// export default (props) => {
export default class SessionBar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {showTime: '', durationLeft: '02:00:00', ratio: 4, status: 'wait', lastTime: 0, waitforserver: false, showEndSessionConfirm: false};
    this.system = getData('system', {});
  }

  doTimer = () => {
    const {session} = this.props;
    let newState = {};
    if (session.status === 'on') {
      const nowTimeStamp = new Date().getTime();
      const diffMSecs = nowTimeStamp - this.lastTime.timeStamp;
      const showTime = moment(this.lastTime.time, 'HH:mm:ss').add(diffMSecs * (session.ratio || 4), 'milliseconds').format('HH:mm:ss');
      if (showTime > this.state.showTime) newState.showTime = showTime;

      const msecLeft = moment.duration('17:00:00').subtract(moment.duration(showTime)).as('milliseconds') / (session.ratio || 4);
      const durationLeft = moment(msecLeft).utc().format('HH:mm:ss');
      if (!this.state.sliding && durationLeft !== this.state.durationLeft) newState.durationLeft = durationLeft;
      if ((newState.showTime || newState.durationLeft) && !this.state.waitforserver) this.setState(newState);
    } else if (!this.state.showTime && session.time) this.setState({showTime: session.time});

    // console.log(this.state.ratio);
  }

  componentDidMount = () => {
    this.tickTimer = setInterval(this.doTimer, 100);
    this.sessionWatcher = registerEvent('session', (session) => this.setState({waitforserver: false}));
  }
  componentWillUnmount = () => {
    clearInterval(this.tickTimer);
    unRegisterEvent(this.sessionWatcher);
  }

  componentDidUpdate = ({session = {}}) => {
    if (session.time !== this.props.session.time || session.status !== this.props.session.status) {
      this.lastTime = {timeStamp: new Date().getTime(), time: this.props.session.time};
    }
  }

  updateRemaining = (mins, sendToServer) => {
    mins = Math.max(mins, 5);
    const pad = (what) => what < 10 ? `0${what}` : what;
    const durationLeft = pad(Math.floor(mins / 60)) + ':' + pad(mins % 60) + ':00';
    this.setState({durationLeft});
    if (sendToServer) {
      this.setState({waitforserver: true});
      send('session', {type: 'remaining', value: mins});
    }
  }

  adminBtns = (action) => {
    if (action === 'start' || action === 'freeze' || action === 'unfreeze') send('session', {type: action});
    if (action === 'end' || action === 'endCancel') this.setState({showEndSessionConfirm: action === 'end'});
    if (action === 'endConfirm') {
      this.setState({showEndSessionConfirm: false});
      send('session', {type: 'end'});
    }
  }

  render () {
    const {session} = this.props;
    const showStartButton = session.status === 'wait';
    const showEndButton = session.status && session.status !== 'wait' && session.status !== 'end';
    const showFreezeButton = showEndButton && session.status !== 'frozen';
    const showUnFreezeButton = session.status === 'frozen';
    const showTimeBar = session.status !== 'end';
    const {showEndSessionConfirm} = this.state;
    return (
      <div className="session-bar">
        <div className="session-bar-left">
          {showEndSessionConfirm ? (
            <>
              <p className="title-text mb-0">End session?</p>
              <div className="session-bar-buttons">
                <button className="btn btn-sm btn-danger" style={{minWidth: '100px'}} onClick={() => this.adminBtns('endCancel')}>Cancel</button>
                <button className="btn btn-sm btn-success ml-2" style={{minWidth: '100px'}} onClick={() => this.adminBtns('endConfirm')}>Yes</button>
              </div>
            </>
          ) : (
            <>
              <p className="title-text mb-0">Session ({this.system.name})</p>
              <div className="session-bar-buttons">
                {showStartButton ? <button className="btn btn-sm btn-success" onClick={() => this.adminBtns('start')}>Start Session</button> : null}
                {showEndButton && !showEndSessionConfirm ? <button className="btn btn-sm btn-danger" onClick={() => this.adminBtns('end')}>End Session</button> : null}

                {showFreezeButton ? <button className="btn btn-sm btn-primary ml-2" onClick={() => this.adminBtns('freeze')}>Freeze Session</button> : null}
                {showUnFreezeButton ? <button className="btn btn-sm btn-primary ml-2" onClick={() => this.adminBtns('unfreeze')}>Unfreeze Session</button> : null}
              </div>
            </>
          )}
        </div>
        <div className="session-bar-middle">
          <p className="title-text mb-0">Time</p>
          <div className="session-bar-buttons">
            <TimeValue title="Current" value={this.state.showTime} timeClassName={session.status}/>
            <TimeValue title="Remaining" value={this.state.durationLeft} timeClassName={session.status} sliding={this.state.sliding} className="ml-2"/>
          </div>
        </div>
        <div className="session-bar-right">
          {showTimeBar ? <TimeBar value={this.state.durationLeft} onUpdate={(val) => this.updateRemaining(val, false)} onChange={(val) => this.updateRemaining(val, true)} onSlide={(sliding) => this.setState({sliding})}/> : null}
        </div>
      </div>
    );
  }
}
