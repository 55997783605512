
let functions = {};

exports.registerEvent = (tag, action) => {
  const id = new Date().getTime() + Math.round(Math.random() * 1000000);
  if (!functions[tag]) functions[tag] = {};
  functions[tag][id] = action;
  return id;
};

exports.unRegisterEvent = (...ids) => {
  for (const id of ids) {
    for (let tag in functions) {
      if (functions[tag][id]) {
        delete functions[tag][id];
      }
    }
  }
};

exports.fireEvent = (tag, data) => {
  if (functions[tag]) {
    for (let id in functions[tag]) functions[tag][id](data);
  }
};
