import React from 'react';

export default class Dealers extends React.Component {
  render () {
    return (
      <div style={{display: this.props.isVisible ? '' : 'none', flex: 1}}>
        Prices
      </div>
    );
  }
}
