import React from 'react';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';
import {getData, setData} from 'lib/data';
import Graph from './Graph';

export default class Dealers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {dealers: getData('dealers') || []};
  }

  componentDidMount = async () => this.dealerWatcher = registerEvent('dealer', this.updateDealer);
  componentWillUnmount = () => unRegisterEvent(this.dealerWatcher);

  updateDealer = (dealerData) => {
    let {dealers} = this.state;
    if (dealerData.dealer) dealers[dealerData.dealer] = {...(dealers[dealerData.dealer] || {}), ...dealerData};
    this.setState({dealers});
    setData('dealers', dealers);
  }

  render () {
    const {market} = this.props;
    const dealers = this.state.dealers.map((d, index) => ({...d, index})).filter(d => d.dealer > 0).sort((a, b) => a.dealer - b.dealer).map(d => ({...d, unrealised: getUnrealised(d, market)}));
    return (
      <div style={{display: this.props.isVisible ? 'flex' : 'none', flex: 1, flexDirection: 'column'}}>
        <div className="d-flex p-2" style={{overflowX: 'scroll'}}>
          {dealers.map(dealer => (
            <Dealer key={dealer.dealer} dealer={dealer} market={market}/>
          ))}
        </div>
        {dealers.length ? <Graph dealers={dealers} market={market}/> : null}
      </div>
    );
  }
}

const Dealer = ({dealer, market}) => {
  return (
    <div style={{maxWidth: '240px', minWidth: '160px', height: '100px', padding: '4px', display: 'flex'}}>
      <div style={{flex: 1, display: 'flex', flexDirection: 'column', border: '1px solid #333', backgroundColor: dealer.connected ? '#ADA' : '#DAA', alignItems: 'center', padding: '4px', textOverflow: 'clip', overflow: 'hidden'}}>
        <p className='m-0 p-0' style={{lineHeight: 1.4}}><b>{dealer.name}</b></p>
        <p className='m-0 p-0' style={{lineHeight: 1.3}}>"{dealer.nickname}"</p>
        <p className='m-0 p-0' style={{lineHeight: 1.3}}>{dealer.position || 0} @ {(dealer.aveprice || 0).toFixed(2)}</p>
        <p className='m-0 p-0' style={{lineHeight: 1.3}}>{`UR: ${(dealer.unrealised || 0).toFixed(2)}, R: ${(dealer.realised || 0).toFixed(2)}`}</p>
      </div>
    </div>
  );
};

const getUnrealised = (dealer, market) => {
  if (!dealer || !market || !market.b) return 0;
  const {position = 0, aveprice = 0} = dealer;
  let unrealised = 0;
  if (position > 0 && market.b) unrealised = position * (market.b / 100 - aveprice);
  if (position < 0 && market.o) unrealised = position * (market.o / 100 - aveprice);
  return unrealised;
};
