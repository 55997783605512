import React from 'react';
import Title from '../components/Title';
import {FlexibleXYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis} from 'react-vis';
import {getData} from 'lib/data';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';
import 'react-vis/dist/style.css';
import moment from 'moment';

export default class Graph extends React.Component {
  state = {chartData: [], time: '07:00', minute: 0, startTime: 7, endTime: 17};

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.renderChart();
    this.minuteWatcher = registerEvent('minute', () => this.timerId = setTimeout(this.renderChart, 200));
    this.sessionWatcher = registerEvent('session', (session) => {
      if (session.start) this.setState({startTime: session.start});
      this.setMins(session.time);
    });
  }

  resize = () => {
    clearTimeout(this.showChart);
    this.setState({resetChart: true});
    this.showChart = setTimeout(() => this.setState({resetChart: false}), 100);
  }

  setMins = (time) => {
    if (!time) return;
    const [h, m] = time.split(':').map(hm => parseInt(hm, 10));
    this.minutes = (h * 60 + m - this.state.startTime * 60);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timerId);
    window.removeEventListener('resize', this.resize);
    unRegisterEvent(this.minuteWatcher, this.sessionWatcher);
  }

  renderChart = (time) => {
    const dataVals = getData('market_1');
    let chartData = [];
    for (const minute in dataVals) {
      if (dataVals[minute] && minute <= this.minutes) chartData.push({x: parseInt(minute, 10), y: dataVals[minute]});
    }
    let newState = {chartData};

    if (time) newState.time = time.substr(0, 5);
    this.setState(newState);
  }

  render () {
    return (
      <div className="inner-container">
        <Title>Graph</Title>
        <div style={localStyle.graphBox}>
          {this.state.resetChart ? null : <Chart data={this.state.chartData} startTime={this.state.startTime} endTime={this.state.endTime}/>}
        </div>
      </div>
    );
  }
}

const Chart = (props) => {
  const {startTime, endTime} = props;
  const hours = endTime - startTime;
  const ticks = Array(hours * 2 + 1).fill(0).map((item, index) => index * 30);
  return (
    <FlexibleXYPlot xDomain={[0, hours * 60]} margin={{left: 50, right: 10, top: 10, bottom: 40}}>
      <VerticalGridLines tickValues={ticks}/>
      <HorizontalGridLines />
      <XAxis tickValues={ticks} tickFormat={v => moment().set({hour: startTime + Math.floor(v / 60), minute: (v % 60)}).format('HH:mm')}/>
      <YAxis tickFormat={v => (v / 100).toFixed(2)}/>
      <LineSeries data={props.data} color='red'/>
    </FlexibleXYPlot>
  );
};

const localStyle = {
  graphBox: {flex: 1, display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 4}
};
