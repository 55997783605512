import React from 'react';
import {server} from 'lib/comms';
import {getData} from 'lib/data';
import {fireEvent} from 'lib/eventlinks';
import {doDeal, twoWayQuote, formatPrice} from 'lib/markets';
import './lines.css';

export default class Line extends React.Component {
  constructor (props) {
    super(props);
    this.state = {status: 'idle', bid: '', offer: ''};
    this.timeToAnswer = 1000;
    this.hangupTimer = 0;
    this.maxSpread = 10;
    this.maxDist = 10;
  }

  componentDidMount = () => this.mounted = true;
  componentWillUnmount = () => this.mounted = false;

  getStatus = () => this.state.status;

  setHangupTimer = (delay) => {
    clearTimeout(this.hangupTimer);
    this.hangupTimer = setTimeout(() => this.mounted && this.setState({status: 'idle'}), delay);
  }

  doDeal = (amount, price, type) => {
    const {bank} = this.props;
    const {caller} = this.state;
    if (type === 'out') {
      fireEvent('blotter', {text: `Called ${caller || bank.name}, ${amount > 0 ? 'bought' : 'sold'} ${Math.abs(amount)} Crystals at ${formatPrice(price)}`, amount});
      this.setState({status: 'donedeal', dealtext: `${amount > 0 ? 'bought' : 'sold'} ${Math.abs(amount)} at ${formatPrice(price)}`});
      doDeal({market: 1, price, amount: parseInt(amount), type: 'callout', whoWith: caller || bank.name});
      this.setHangupTimer(2000);
    } else {
      fireEvent('blotter', {text: `${caller || bank.name} called, we ${amount > 0 ? 'bought' : 'sold'} ${Math.abs(amount)} Crystals at ${formatPrice(price)}`, amount});
      this.setState({status: 'donedeal', dealtext: `we ${amount > 0 ? 'bought' : 'sold'} ${Math.abs(amount)} at ${formatPrice(price)}`});
      doDeal({market: 1, price, amount: parseInt(amount), type: 'callin', whoWith: caller || bank.name});
      this.setHangupTimer(5000);
    }
  }

  cancelClick = e => {
    if (e.button === 2) {
      e.preventDefault();
      clearTimeout(this.hangupTimer);
      this.setState({status: 'idle'});
    }
  }

  // outgoing call, bank quoting
  doQuote = () => {
    if (this.state.status !== 'callout') return this.setState({status: 'idle'});
    const currentMarkets = getData('markets') || [];
    if (currentMarkets[1]) {
      const {b, o} = currentMarkets[1];
      const buyPercent = getBuyPercent(this.props.bank.buy, this.props.buyBias); // Math.min(Math.max(parseInt(this.props.bank.buy || 50) + (this.props.buyBias || 0), 0), 100);
      const delta = Math.round(0.05 * (buyPercent - Math.random() * 100));

      let deltaSpread = Math.random() < 0.4 ? 1 : 0;
      if (o - b <= 4) deltaSpread *= -1;

      const quote = {b: Math.min(b + delta + deltaSpread, o), o: Math.max(o + delta - deltaSpread, b)};
      this.setState({status: 'calloutquote', quote});
      this.setHangupTimer(10000);
    } else this.setState({status: 'idle'});
  }

  callout = (e) => {
    if (this.props.enabled) {
      fireEvent('sound', {sound: 'callout.mp3'});
      this.setState({status: 'callout', amount: 5, caller: null});
      setTimeout(this.doQuote, this.timeToAnswer * (0.6 + Math.random()));
    }
  }

  callin = (call) => {
    fireEvent('sound', {sound: 'callin.mp3'});
    this.setState({status: 'callin', bid: '', offer: '', caller: call ? call.caller : null, amount: call ? call.amount || 5 : 5, call});
    this.setHangupTimer(10000);
  }

  callinAnswer = (e) => {
    this.setHangupTimer(20000);
    const [, market] = getData('markets') || [];
    if (market) {
      const priceStart = getBigFig(market);
      this.setState({status: 'callinquote', priceStart}, () => this.bidText.focus());
    }
  }

  callinRequote = () => {
    clearTimeout(this.dealTimer);
    this.setState({status: 'callinquote'}, () => this.bidText.focus());
  }

  callinSubmit = (e) => {
    const setError = (error) => this.setState({errorMessage: error}, () => setTimeout(() => this.setState({errorMessage: null}), 1000));

    const [, market] = getData('markets') || [];
    const {bid, offer} = this.state;
    const priceStart = getBigFig(market, parseInt(bid));
    let quotedBid = priceStart * 100 + parseInt(bid, 10);
    let quotedOffer = priceStart * 100 + parseInt(offer, 10);
    if (isNaN(quotedBid) || isNaN(quotedOffer)) return setError('Please enter a valid quote');
    if (quotedOffer < quotedBid) quotedOffer += 100;
    if (quotedOffer - quotedBid > Math.max(this.maxSpread, market.o - market.b)) return setError(`Max spread is ${this.maxSpread} points`);
    if (market.b - quotedBid > this.maxDist || quotedOffer - market.o > this.maxDist) return setError(`Quotes must be within ${this.maxDist} points of the market`);

    this.setState({status: 'callinquoted', quotedBid, quotedOffer});
    this.dealTimer = setTimeout(this.callInDeal, this.timeToAnswer * (1.6 + Math.random()));
  }

  // decide if to deal or not
  callInDeal = () => {
    const {bank} = this.props;
    const {caller, amount = 5, call, quotedBid, quotedOffer} = this.state;
    const [, market] = getData('markets') || [];
    let buyer, accept;
    if (call && call.amount) {
      buyer = call.buy;
      console.log(buyer, quotedBid - market.b, call.at);
      accept = buyer ? quotedOffer - market.o <= call.at : quotedBid - market.b >= -call.at;
    } else {
      const buyPercent = getBuyPercent(bank.buy, this.props.buyBias);
      buyer = (Math.random() * 100 < buyPercent);
      const threshold = Math.round((buyPercent - 50) / 25);
      console.log(buyer, threshold);
      accept = buyer ? quotedOffer - market.o <= threshold : quotedBid - market.b >= threshold;
    }
    if (accept) {
      if (buyer) this.doDeal(-amount, quotedOffer, 'in');
      else this.doDeal(amount, quotedBid, 'in');
    } else {
      this.setState({status: 'donedeal', dealtext: caller ? `${caller} declined` : 'declined'});
      this.setHangupTimer(5000);
    }
  }

  textChange = (field, value) => {
    let newState = {};
    newState[field] = value;
    if (value.length > 2 || isNaN(value) || value.indexOf('-') >= 0 || value.indexOf('.') >= 0) return;
    if (field === 'bid' && value.length === 2 && this.offerText) {
      this.offerText.focus();
      const [, market] = getData('markets') || [];
      newState.priceStart = getBigFig(market, parseInt(value));
    }
    this.setState(newState);
  }

  onRef = (field, ref) => this[field] = ref;

  render () {
    const {bank} = this.props;
    const {status, quote, dealtext, bid, offer, priceStart, errorMessage, quotedBid, quotedOffer, caller, amount = 5} = this.state;
    if (status === 'callin') return <CallingIn name={caller || bank.name} amount={amount} onClick={this.callinAnswer} onCancel={this.cancelClick}/>;
    if (status === 'callinquote' && errorMessage) return <CallingInError errorMessage={errorMessage} onCancel={this.cancelClick} onClick={() => this.setState({errorMessage: null})}/>;
    if (status === 'callinquote') {
      // setTimeout(() => this.bidText && this.bidText.focus(), 200);
      return <CallingInQuote name={caller || bank.name} priceStart={priceStart} onClick={this.cancelClick} onTextChange={this.textChange} bid={bid} offer={offer} onRef={this.onRef} onSubmitQuote={this.callinSubmit}/>;
    }
    if (status === 'callinquoted') return <CallingInQuoted name={caller || bank.name} amount={amount} quotedBid={quotedBid} quotedOffer={quotedOffer} onCancel={this.cancelClick} onBack={this.callinRequote}/>;

    if (status === 'calloutquote' && quote) return <CallingOutQuote bank={bank} amount={amount} onClick={this.cancelClick} quote={quote} onBuy={() => this.doDeal(5, quote.o, 'out')} onSell={() => this.doDeal(-5, quote.b, 'out')}/>;
    if (status === 'callout') return <CallingOut bank={bank} onClick={this.cancelClick}/>;
    if (status === 'donedeal') return <DoneDeal bank={bank} text={dealtext} onClick={this.cancelClick}/>;
    return <Idle bank={bank} onClick={this.callout}/>;
  }
}

const CallingIn = ({name, onClick, onCancel, amount}) => (
  <div className='hoverin calling-in' style={{...localStyle.lineBoxOuter, ...localStyle.calling}} onClick={onClick} onContextMenu={onCancel}>
    <div className="noselect" style={localStyle.centered}>{name} Calling ({amount || '5'})</div>
  </div>
);

const CallingInError = ({errorMessage, onClick, onCancel}) => (
  <div className='hoverin calling-in' style={{...localStyle.lineBoxOuter, ...localStyle.calling}} onClick={onClick} onContextMenu={onCancel}>
    <div className="noselect" style={localStyle.centered}>{errorMessage}</div>
  </div>
);

const CallingInQuote = ({name, priceStart, onClick, amount, bid, offer, onTextChange, onRef, onSubmitQuote}) => (
  <div className='calling-in' style={{...localStyle.lineBoxOuter}} >
    <div style={{...localStyle.calling}} onContextMenu={onClick}>
      <div className="noselect" style={localStyle.centered}>{priceStart}.</div>
      <input style={localStyle.input} value={bid} onKeyPress={({nativeEvent}) => nativeEvent.key === 'Enter' && onSubmitQuote()} onChange={e => onTextChange('bid', e.target.value)} ref={ref => onRef('bidText', ref)} onFocus={(e) => e.target.select()}/>
      -
      <input style={localStyle.input} value={offer} onKeyPress={({nativeEvent}) => nativeEvent.key === 'Enter' && onSubmitQuote()} onChange={e => onTextChange('offer', e.target.value)} ref={ref => onRef('offerText', ref)} onFocus={(e) => e.target.select()}/>
    </div>
    <Button style={{backgroundColor: '#0CC', fontSize: 20}} onClick={onSubmitQuote}>✓</Button>
  </div>
);

const CallingInQuoted = ({name, amount, quotedBid, quotedOffer, onCancel, onBack}) => (
  <div className='calling-in' style={{...localStyle.lineBoxOuter}} onContextMenu={onCancel}>
    <Button style={{backgroundColor: '#0CC', fontSize: 20}} onClick={onBack}>↻</Button>
    <div style={{...localStyle.callingTexts, marginRight: 30}}>
      <div className="noselect" style={localStyle.centered}>{name}{amount && amount !== 5 ? ` (${amount})` : ''}</div>
      <div className="noselect" style={localStyle.centered}>{twoWayQuote(quotedBid, quotedOffer)}</div>
    </div>
  </div>
);

const CallingOut = ({bank, onClick, amount}) => (
  <div className='calling-out' style={{...localStyle.lineBoxOuter, ...localStyle.calling}} onContextMenu={onClick}>
    <div className="noselect" style={localStyle.centered}>Calling {bank.name} ({amount || '5'})</div>
  </div>
);

const DoneDeal = ({bank, onClick, text}) => (
  <div style={{...localStyle.lineBoxOuter, ...localStyle.callingTexts, backgroundColor: '#F0F'}} onClick={onClick} onContextMenu={onClick}>
    <div className="noselect" style={localStyle.centered}>{bank.name}</div>
    <div className="noselect" style={localStyle.centered}>{text}</div>
  </div>
);

const CallingOutQuote = ({bank, quote, amount, onClick, onBuy, onSell}) => (
  <div className='calling-out' style={{...localStyle.lineBoxOuter, ...localStyle.calling}} onContextMenu={onClick}>
    <Button onClick={onSell}>S</Button>
    <div style={localStyle.callingTexts}>
      <div className="noselect" style={localStyle.centered}>{bank.name}</div>
      <div className="noselect" style={localStyle.centered}>{twoWayQuote(quote.b, quote.o)}</div>
    </div>
    <Button style={{backgroundColor: '#08F'}} onClick={onBuy}>B</Button>
  </div>
);

const Idle = ({bank, onClick}) => (
  <div className={bank.name ? 'hover' : ''} style={localStyle.lineBoxOuter} onClick={bank.name ? onClick : null} onContextMenu={e => e.preventDefault()}>
    {bank.pic ? <Avatar pic={bank.pic} name={bank.name}/> : null}
    {bank.name ? <div className="noselect" style={localStyle.title}>{bank.name}</div> : null}
  </div>
);

const Avatar = (props) => <div style={{margin: 10}}><img className="noselect" alt={props.name} style={localStyle.avatar} src={`${server}/public/pics/${props.pic}.jpg`}></img></div>;

const Button = (props) => <button className="btn" onClick={props.onClick} style={{...localStyle.button, ...props.style}}>{props.children}</button>;

const localStyle = {
  lineBoxOuter: {flex: 1, margin: 1, display: 'flex', padding: 0, border: '1px solid #999', backgroundColor: '#000', color: '#9AF', fontWeight: 600, alignItems: 'center'},
  calling: {color: '#000', flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'},
  callingTexts: {color: '#000', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center'},
  avatar: {height: '100%'},
  centered: {textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'},
  button: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 40, minWidth: 40, padding: 0, fontWeight: 600, backgroundColor: 'red', alignSelf: 'stretch'},
  title: {marginLeft: 0},
  input: {textAlign: 'center', border: '1px solid #000', width: 60, fontWeight: 500, marginLeft: 2, marginRight: 4}
};

const getBigFig = (market, bid) => {
  const priceStart = market ? Math.floor(market.b / 100) : 361;
  if (bid && market) {
    const tryBid = priceStart * 100 + parseInt(bid, 10);
    if (tryBid - market.b > 50) return priceStart - 1;
    if (market.b - tryBid > 50) return priceStart + 1;
  }
  return priceStart;
};

const getBuyPercent = (base, override) => {
  let buyPercent = parseInt(base || 50) + (override || 0);
  buyPercent = Math.min(buyPercent, 100);
  buyPercent = Math.max(buyPercent, 0);
  return buyPercent;
};
