import React from 'react';
import Title from '../components/Title';
import {doDeal, formatPrice} from 'lib/markets';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';

import './needles.css';

export default class NeedlesWafers extends React.Component {
  state = {}

  componentDidMount = () => {
    this.mounted = true;
    this.marketWatcher = registerEvent('market_' + this.props.market, this.updateMarket);
    // this.minuteWatcher = registerEvent('minute', (time) => addTimedEntry(time, 'market_' + this.props.market, (this.state.b + this.state.o) / 2));
  }

  updateMarket = (prices) => {
    clearTimeout(this.whiteTimer);
    prices.o = prices.o || (prices.b + Math.round(Math.random() * 2) + 6);
    const bidDelta = prices.b - this.state.b;
    const bidColor = bidDelta > 0 ? '#88F' : bidDelta < 0 ? '#F88' : '#FFF';
    const offerDelta = prices.o - this.state.o;
    const offerColor = offerDelta > 0 ? '#88F' : offerDelta < 0 ? '#F88' : '#FFF';
    this.setState({...prices, bidColor, offerColor});
    this.whiteTimer = setTimeout(() => this.setState({bidColor: '#FFF', offerColor: '#FFF'}), 500);
  }

  componentWillUnmount = () => {
    clearTimeout(this.whiteTimer);
    unRegisterEvent(this.marketWatcher);
  }

  onSell = async () => {
    if (!this.props.enabled) return;
    const {market, conversion, title} = this.props;
    const {b: price, ba} = this.state;
    if (price && ba >= conversion) {
      await doDeal({market, price, amount: -5, type: 'market', efficiency: (100 - this.state.t) / 100, conversion});
      fireEvent('blotter', {text: `Sold ${conversion} ${title} at ${formatPrice(price)}`, amount: -5, market});
    }
  }

  render () {
    const {b, ba, t = 0} = this.state;
    const {title = 'Needles', conversion = 5} = this.props;
    return (
      <div className="inner-container-non-flex" style={{overflowY: 'hidden', minHeight: 100}}>
        <Title>{title} ({conversion} {title} = 5 Crystals)</Title>
        <div style={localStyle.marketBox}>
          <div style={localStyle.row}>
            <div style={{...localStyle.box, backgroundColor: this.state.bidColor}}>{formatPrice(b || 0)}</div>
            <div style={localStyle.box}>{ba || 0}</div>
          </div>
          <div style={localStyle.row}>
            <Button onClick={this.onSell} disabled={this.state.selling} style={{backgroundColor: '#F30'}}>SELL</Button>
            <div style={{...localStyle.box, backgroundColor: '#EEE'}}>Eff: {(100 - t).toFixed(1)}%</div>
          </div>
        </div>
      </div>
    );
  }
}

const Button = (props) => {
  if (props.disabled) return <button className="btn" onClick={props.onClick} style={{...localStyle.button, backgroundColor: '#999'}} disabled>{props.children}</button>;
  return <button className="btn" onClick={props.onClick} style={{...localStyle.button, ...props.style}}>{props.children}</button>;
};

const localStyle = {
  marketBox: {flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'stretch', backgroundColor: '#FFF', padding: 4},
  row: {display: 'flex', flexDirection: 'row', justifyContent: 'space-around'},
  box: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center'},
  button: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, height: 30, padding: 0, fontWeight: 600}
};
