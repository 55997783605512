import React from 'react';

import Title from '../components/Title';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';
import {doAccounting} from 'lib/markets';
import {addTimedEntry} from 'lib/data';

export default class Market extends React.Component {
  state = {}

  componentDidMount = () => {
    this.accountWatcher = registerEvent('account', data => this.setState(data));
    this.marketWatcher = registerEvent('market_1', market => this.setState({market}));
    this.minuteWatcher = registerEvent('minute', (time) => {
      const {position = 0, avePrice = 0, realised = 0, market} = this.state;
      addTimedEntry(time, 'position', position);
      const unrealised = market ? (position > 0 ? position * (market.b / 100 - avePrice) : position * (market.o / 100 - avePrice)) : 0;
      addTimedEntry(time, 'profit', realised + unrealised);
    });
    doAccounting();
  }

  componentWillUnmount = () => unRegisterEvent(this.accountWatcher, this.marketWatcher, this.minuteWatcher);

  render () {
    const {position = 0, avePrice = 0, realised = 0, market} = this.state;
    const unrealised = market ? (position > 0 ? position * (market.b / 100 - avePrice) : position * (market.o / 100 - avePrice)) : 0;
    return (
      <div className="inner-container">
        <Title>My Account</Title>
        <div style={localStyle.accountsBox}>
          <AccountLine title='My Position:' value={position}/>
          <AccountLine title='Ave Price:' value={avePrice.toFixed(2)}/>
          <AccountLine title='Unrealised:' value={unrealised.toFixed(2)}/>
          <AccountLine title='Realised:' value={realised.toFixed(2)}/>
        </div>
      </div>
    );
  }
}

const AccountLine = (props) => (
  <div style={localStyle.accountLine}>
    <div style={localStyle.accountTitle}>{props.title}</div>
    <div style={localStyle.accountBox}>{props.value}</div>
    <div style={{flex: 1}}/>
  </div>
);

const localStyle = {
  accountsBox: {flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', backgroundColor: '#FFF'},
  accountLine: {flexDirection: 'row', display: 'flex', justifyContent: 'space-between', padding: 1, alignItems: 'flex-start'},
  accountTitle: {textAlign: 'right', marginRight: 20, width: 110},
  accountBox: {backgroundColor: '#FFF', border: '1px solid #000', maxWidth: 120, minWidth: 120, marginRight: 0, textAlign: 'right', fontWeight: 600, paddingRight: 10}
};
