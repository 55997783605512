import React from 'react';
import Header from 'components/layout/Header';

export default (props) => (
  <div className="outer">
    {props.header ? <Header connected={props.header.connected} title={props.header.title} session={props.header.session}/> : null}
    <div className="full-center">
      <h5>{props.text}</h5>
    </div>
  </div>
);
