import React from 'react';
import {deleteData, setDataObj, setData} from './lib/data';
import LoginPage from 'pages/login/LoginPage';
import AdminPage from './admin/AdminPage';
import DealerPage from './dealer/DealerPage';
import cookie from 'react-cookies';
import {decodeToken} from 'react-jwt';
import {get} from 'lib/comms';
const socket = require('./lib/socket');

const appPackage = require('../package.json') || {};

export default class App extends React.Component {
  constructor (props) {
    super(props);
    console.log(`${appPackage.name} ${appPackage.version} ${process.env.NODE_ENV}`);
    const token = cookie.load('nts_token');
    const userData = decodeToken(token) || {};
    socket.admin(userData.dealer === 999);
    this.state = {loaded: false, ...userData};
  }

  componentDidMount = async () => {
    const resp = await get('api/data/data');
    if (resp.system) setDataObj(resp);
    this.setState({loaded: true});
  }

  logout = (renderProps) => deleteData('token', '', true);

  login = (user) => {
    setData('user', user);
    this.setState({user});
  }

  render () {
    const {dealer, loaded} = this.state;
    if (!loaded) return <LoadingPage/>;
    if (dealer === 999) return <AdminPage/>;
    else if (dealer) return <DealerPage/>;
    return <LoginPage onLogin={this.login}/>;
  }
}

const LoadingPage = () => (
  <div className="outer">
    <div className="full-center">

    </div>
  </div>
);
