import React from 'react';
import NeedlesWafers from './NeedlesWafers';
import NonEarth from './NonEarth';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {addRealised, calcDeal, doDeal} from 'lib/markets';
import {getData} from 'lib/data';

import './needles.css';

const transportMins = 60;
const moment = require('moment');

export default class NeedleBox extends React.Component {
  constructor (props) {
    super(props);
    this.system = getData('system', {});
    this.state = {minsToGo: 30};
    this.lastTransport = '';
  }

  componentDidMount = async () => {
    this.mounted = true;
    this.sessionWatcher = registerEvent('session', ({time}) => time && this.updateTime(time.substr(0, 5)));
    this.updateTime(this.time);
  }

  componentWillUnmount = () => {
    this.mounted = false;
    unRegisterEvent(this.sessionWatcher);
  }

  updateTime = (time) => {
    const minsToGo = transportMins - moment(time, 'HH:mm').minutes() % transportMins;
    if (minsToGo === transportMins && time !== this.lastTransport) this.doTransport(time);
    this.setState({minsToGo});
    // this.doTransport(time);
  }

  doTransport = (time) => {
    this.lastTransport = time;
    let {position: m4Pos = 0, avePrice: m4Price = 0, c23, c: c4 = 0} = this.m4.getInfo();
    let {position: m5Pos = 0, avePrice: m5Price = 0, c: c5 = 0} = this.m5.getInfo();
    if (m4Pos || m5Pos) {
      if (m4Pos * m5Pos < 0) {
        const amount = Math.min(Math.abs(m4Pos), Math.abs(m5Pos));
        const is4to5 = m4Pos > 0;
        m4Pos += is4to5 ? -amount : amount;
        m5Pos += is4to5 ? amount : -amount;
        let {realised} = calcDeal(-amount, (is4to5 ? m5Price : m4Price) * 100, amount, is4to5 ? m4Price : m5Price);
        realised -= c23 * Math.ceil(amount / (this.system.p23lot || 50));
        addRealised(realised, is4to5 ? 4 : 5);
        fireEvent('blotter', {text: `Moved ${amount} ${this.system.markets[1]} from ${is4to5 ? this.system.markets[4] : this.system.markets[5]} to ${is4to5 ? this.system.markets[5] : this.system.markets[4]} for a profit of ${realised.toFixed(2)}`, amount, market: 4});
      }
      if (m4Pos) {
        doDeal({market: 4, amount: m4Pos, price: m4Price * 100, type: 'transport'});
        const realised = c4 * Math.ceil(Math.abs(m4Pos) / (this.system.p2lot || 50));
        if (realised) addRealised(-realised, 4);
        fireEvent('blotter', {text: `Moved ${Math.abs(m4Pos)} ${this.system.markets[1]} from ${m4Pos > 0 ? this.system.markets[4] : 'Earth'} to ${m4Pos > 0 ? 'Earth' : this.system.markets[4]} at a price of ${m4Price.toFixed(2)} and a cost of ${realised.toFixed(2)}`, amount: m4Pos, market: 4});
      }
      if (m5Pos) {
        doDeal({market: 5, amount: m5Pos, price: m5Price * 100, type: 'transport'});
        const realised = c5 * Math.ceil(Math.abs(m5Pos) / (this.system.p3lot || 50));
        if (realised) addRealised(-realised, 5);
        fireEvent('blotter', {text: `Moved ${Math.abs(m5Pos)} ${this.system.markets[1]} from ${m5Pos > 0 ? this.system.markets[5] : 'Earth'} to ${m5Pos > 0 ? 'Earth' : this.system.markets[5]} at a price of ${m5Price.toFixed(2)} and a cost of ${realised.toFixed(2)}`, amount: m5Pos, market: 5});
      }
      setTimeout(() => fireEvent('sound', {sound: 'transport.mp3'}), 250);
    }
    this.m4.clearPosition();
    this.m5.clearPosition();
  }

  render () {
    const {minsToGo = 0} = this.state;
    const {enabled} = this.props;
    return (
      <div className="needlebox-outer">
        <NeedlesWafers type="needles" title="Needles" conversion={4} market={2} enabled={enabled}/>
        <NeedlesWafers type="wafers" title="Wafers" conversion={6} market={3} enabled={enabled}/>
        <NonEarth title={this.system.markets[4]} market={4} other={this.system.markets[5]} minsToGo={minsToGo} transportMins={transportMins} ref={ref => this.m4 = ref} lot={this.system.p2lot} otherLot={this.system.p23lot} enabled={enabled}/>
        <NonEarth title={this.system.markets[5]} market={5} other={this.system.markets[4]} minsToGo={minsToGo} transportMins={transportMins} ref={ref => this.m5 = ref} lot={this.system.p3lot} otherLot={this.system.p23lot} enabled={enabled}/>
      </div>
    );
  }
}
