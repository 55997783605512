import openSocket from 'socket.io-client';
import {fireEvent} from 'lib/eventlinks';
import {setMarketPrices} from 'lib/markets';
import {server} from './comms';

let socket;
let isAdmin;

export const connect = () => {
  socket = openSocket(server);
  socket.on('connect', () => fireEvent('connected', true));
  socket.on('disconnect', () => fireEvent('connected', false));
  socket.on('message', (msg) => fireEvent('message', msg));
  socket.on('session', (msg) => fireEvent('session', msg));
  socket.on('chat', (msg) => fireEvent('chat', msg));
  if (isAdmin) {
    socket.on('dealer', (msg) => {
      fireEvent('dealer', msg);
    });
  }
  socket.on('market', setMarketPrices);
};

export const send = (type, what) => socket.emit(type, what);

export const admin = (setTo) => {
  if (setTo === true || setTo === false) isAdmin = setTo;
  return isAdmin;
};
