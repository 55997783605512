import React from 'react';
import Market from './Market';
import MyAccount from './MyAccount';
import Working from './Working';
import {getData} from 'lib/data';
import {fireEvent} from 'lib/eventlinks';

export default class MarketBox extends React.Component {
  componentDidMount = () => {
    let markets = getData('markets', {});
    for (const m in markets) fireEvent('market_' + m, markets[m]);
  }

  render () {
    const {enabled} = this.props;
    return (
      <div style={localStyle.marketboxOuter}>
        <MyAccount/>
        <Market market={0} title='Deal with Voice Broker' enabled={enabled}/>
        <Market market={1} title='Deal with Market' enabled={enabled}/>
        <Working enabled={enabled}/>
      </div>
    );
  }
}

const localStyle = {
  marketboxOuter: {width: 300, display: 'flex', flexDirection: 'column', padding: 0}
};
