import React from 'react';
import Title from '../components/Title';
import {getData} from 'lib/data';

import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';

export default class News extends React.Component {
  constructor (props) {
    super(props);
    this.news = getData('news', []).sort((a, b) => a.time < b.time ? 1 : -1);
    this.time = '07:00';
    this.state = {showNews: []};
  }

  componentDidMount = async () => {
    this.mounted = true;
    this.sessionWatcher = registerEvent('session', ({time}) => time && this.updateTime(time.substr(0, 5)));
    this.updateTime(this.time);
  }

  componentWillUnmount = () => {
    this.mounted = false;
    unRegisterEvent(this.sessionWatcher);
  }

  updateTime = (time) => {
    this.time = time;
    const showNews = this.news.filter(item => item.time <= time);
    const playSound = (showNews.length !== this.state.showNews.length || this.state.playSound);
    if (playSound && this.props.enabled) fireEvent('sound', {sound: 'm_news.mp3'});
    if (this.mounted) this.setState({showNews});
  }

  render () {
    const {showNews = []} = this.state;
    return (
      <div className="inner-container" style={localStyle.outer}>
        <Title>News</Title>
        <div style={localStyle.newsBox}>
          <table>
            <tbody>
              {showNews.map((entry, index) => <tr key={index} className="noselect" style={index === 0 ? {fontWeight: 600} : {}}><td valign="top" className="mono" style={{paddingRight: 4, fontSize: 15}}>{entry.time}</td><td style={{paddingTop: 2}}>{entry.story}</td></tr>)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const localStyle = {
  outer: {flex: 1, maxWidth: 800},
  newsBox: {flex: 1, backgroundColor: '#FFF', padding: 4, overflowY: 'scroll'}
};
