import {getData, setData} from 'lib/data';
import {fireEvent} from 'lib/eventlinks';
import {post} from 'lib/comms';

const rebuyNeedles = false;

export const setMarketPrices = (data) => {
  const {m = -1} = data;
  let markets = getData('markets', []);
  if (m >= 0) {
    markets[m] = {...markets[m], ...data};
    setData('markets', markets);
    fireEvent('market_' + m, markets[m]);
    fireEvent('markets', markets);
  }
};

export const doDeal = async (data) => {
  const {market, amount, price, type, efficiency = 1, conversion = 5} = data;
  if (price === 0 || amount === 0) return;
  let dealAmount = 0;
  let markets = getData('markets', []);
  if (type === 'market') {
    if (!markets[market] || !price || !amount) return 0;
    if (amount > 0) {
      dealAmount = markets[market].oa > 0 ? amount : 0;
      if (dealAmount) markets[market].oa = markets[market].oa - (conversion || dealAmount);
    } else {
      dealAmount = markets[market].ba > 0 ? -amount : 0;
      if (dealAmount) markets[market].ba = markets[market].ba - (conversion || dealAmount);
      dealAmount *= -1;
    }
    fireEvent('market_' + market, markets[market]);
    setData('markets', markets);
  }
  if (type === 'callout' || type === 'callin') dealAmount = amount;
  if (type === 'worked') dealAmount = amount;
  if (type === 'transport') dealAmount = amount;

  if (dealAmount === 0) return;

  let dealPrice = price;
  if (parseInt(market, 10) >= 2 && parseInt(market, 10) <= 3) {
    dealPrice = dealPrice * (conversion / 5) * efficiency;
    const {position = 0} = getData('account', {});
    if (position <= 0 && rebuyNeedles) { // do a deal at market offer
      let deals = getData('deals', []);
      deals.push({amount: 5, price: markets[1].o});
      setData('deals', deals);
      fireEvent('blotter', {text: `Bought 5 Crystals from market* at ${formatPrice(markets[1].o)}`, amount: 5, market: 1});
    }
  }

  // accounting...
  let deals = getData('deals', []);
  console.log(dealAmount, dealPrice);
  deals.push({amount: dealAmount, price: dealPrice});
  setData('deals', deals);
  await doAccounting();
};

export const doAccounting = async () => {
  const deals = getData('deals', []);
  let position = 0;
  let avePrice = 0;
  let realised = 0;
  for (const deal of deals) {
    if (deal.amount) {
      let totalPrice = position * avePrice;
      if (deal.amount * position >= 0) { // extending
        totalPrice += deal.amount * deal.price;
        position += deal.amount;
        avePrice = totalPrice / position;
      } else { // reversing
        realised += deal.amount * (avePrice - deal.price) / 100;
        position += deal.amount;
      }
      if (position === 0) avePrice = 0;
    } else if (deal.realised) realised += deal.realised;
  }
  avePrice /= 100;
  setData('account', {position, avePrice, realised});
  fireEvent('account', {position, avePrice, realised});
  await post('sim/dealerupdate', {position, avePrice, realised});
  return true;
};

export const addRealised = (newRealised, market) => {
  let deals = getData('deals', []);
  deals.push({realised: newRealised, market});
  setData('deals', deals);
  doAccounting();
};

export const formatPrice = (price, options = {}) => {
  if (options.decsOnly) {
    price = parseInt(price, 10) % 100;
    if (price < 10) return `0${price}`;
    return price;
  }
  return (price / 100).toFixed(2);
};

export const calcDeal = (amount, price, position, avePrice) => {
  let realised = 0;
  if (amount * position >= 0) {
    let total = position * avePrice + amount * price / 100;
    position += amount;
    avePrice = total / position;
  } else {
    realised = amount * (avePrice - price / 100);
    position += amount;
    if (position === 0) avePrice = 0;
  }
  return {position, avePrice, realised};
};

export const twoWayQuote = (b, o) => {
  return `${formatPrice(b)}-${formatPrice(o, {decsOnly: true})}`;
};
