
import {getData} from 'lib/data';
export const server = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_LIVE;

export const get = async (url, params, includetoken) => {
  if (params) {
    let paramArray = [];
    for (var k in params) paramArray.push(`${k}=${encodeURIComponent(params[k])}`);
    url += `?${paramArray.join('&')}`;
  }
  return sendRequest(url, {method: 'get', headers: {cache: 'force-cache'}}, includetoken);
};

export const getBlob = async (url) => {
  const headers = {cache: 'force-cache', Authorization: 'Bearer ' + getData('token', ''), 'x-application-platform': 'cms', 'x-application-version': '1.0.0'};
  var resp = await fetch(`${server}/${url}`, {headers});
  return resp.blob();
};

export const post = (url, params, includetoken) => sendRequest(url, {method: 'post', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);
export const upload = (url, paramName, file, includetoken) => {
  const formData = new FormData();
  formData.append(paramName, file);
  return sendRequest(url, {method: 'post', body: formData}, includetoken);
};
export const dodelete = (url, params, includetoken) => sendRequest(url, {method: 'delete', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);
export const put = (url, params, includetoken) => sendRequest(url, {method: 'put', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);

async function sendRequest (url, stuff, includetoken) {
  try {
    if (includetoken) stuff.headers = {...stuff.headers, Auth: getData('ntstoken', '')};
    stuff.headers = {...stuff.headers, 'x-application-platform': 'cms', 'x-application-version': '1.0.0', 'Access-Control-Allow-Origin': '*'};
    stuff.credentials = 'include';
    var resp = await fetch(`${server}/${url}`, stuff);
    let respJson = await resp.json();

    if (respJson.status === 'ENC' && respJson.data) return JSON.parse(decrypt(respJson.data));
    else return {status: resp.status, ...respJson};
  } catch (err) {
    console.log(`${server}/${url}`);
    console.log(err);
    return {message: 'There was an error: ' + err.message};
  }
}

// function encrypt (data, key = defaultKey) {
//   const dataArray = data.split('').map((c, i) => c.charCodeAt(0) ^ key.charCodeAt(Math.floor(i % key.length)));
//   return btoa(String.fromCharCode.apply(null, dataArray));
// }

const defaultKey = 'wuHOIUHEWHWEUHHCUuhwuhiweuh';

function decrypt (data, key = defaultKey) {
  const dataArray = atob(data).split('').map(c => c.charCodeAt(0));
  return dataArray.map((c, i) => String.fromCharCode(c ^ key.charCodeAt(Math.floor(i % key.length)))).join('');
}
