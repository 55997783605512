import React from 'react';
import './TimeBar.css';

import {Slider, Handles, Ticks} from 'react-compound-slider';

export default (props) => {
  const [hours, minutes] = (props.value || '02:00').split(':');
  const val = parseInt(hours, 10) * 60 + parseInt(minutes);
  return (
    <div className="time-bar">
      <Slider rootStyle={sliderStyle} domain={[0, 240]} values={[val]} step={1} mode={2} onUpdate={props.onUpdate} onChange={props.onChange} onSlideStart={() => props.onSlide(true)} onSlideEnd={() => props.onSlide(false)}>
        <div style={railStyle} />
        <Handles>
          {({handles, getHandleProps}) => (
            <div className="slider-handles">
              {handles.map(handle => <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps}/>)}
            </div>
          )}
        </Handles>
        <Ticks values={[0, 30, 60, 90, 120, 150, 180, 210, 240]}>
          {({ticks}) => (
            <div className="slider-ticks">
              {ticks.map(tick => <Tick key={tick.id} tick={tick} count={ticks.length} />)}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
};

const sliderStyle = {position: 'relative', width: '100%', height: '100%', border: '0px solid steelblue'};

const railStyle = {position: 'absolute', width: '100%', height: 5, marginTop: 25, borderRadius: 5, backgroundColor: '#8B9CB6'};

function Handle ({handle: {id, value, percent}, getHandleProps}) {
  return (
    <div
      style={{left: `${percent}%`, position: 'absolute', marginLeft: -10, marginTop: 18, zIndex: 2, width: 20, height: 20, border: 0, textAlign: 'center', cursor: 'pointer', borderRadius: '50%', backgroundColor: '#2C4870', color: '#333'}}
      {...getHandleProps(id)}>
      <div style={{fontSize: 11, marginTop: -15, marginLeft: -4}}>
        {minsToHours(value)}
      </div>
    </div>
  );
}

const Tick = ({tick, count}) => {
  return (
    <div>
      <div style={{position: 'absolute', marginTop: 19, width: 1, height: 25, backgroundColor: '#999', left: `${tick.percent}%`}}/>
      <div style={{position: 'absolute', marginTop: 42, fontSize: 11, textAlign: 'center', marginLeft: `${-(100 / count) / 2}%`, width: `${100 / count}%`, left: `${tick.percent}%`}}>
        {minsToHours(tick.value)}
      </div>
    </div>
  );
};

const minsToHours = (mins) => pad(Math.floor(mins / 60)) + ':' + pad(mins % 60);
const pad = (what) => what < 10 ? `0${what}` : what;
