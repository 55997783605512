import React from 'react';

export default (props) => (
  <div style={localStyle.title} className="noselect">
    <div>{props.children}</div>
    {props.rightTitle ? <div>{props.rightTitle}</div> : null}
  </div>
);

const localStyle = {
  title: {padding: '0px 4px', backgroundColor: '#A2D9E1', alignSelf: 'stretch', borderBottom: '1px solid #004', fontSize: 14, fontWeight: 500, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}
};
