import React from 'react';
import Title from '../components/Title';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {getData, setData} from 'lib/data';

import './blotter.css';

export default class Blotter extends React.Component {
  constructor (props) {
    super(props);
    const system = getData('system', {});
    this.time = system.start || '07:00';
    const entries = getData('blotter') || [];
    this.state = {entries};
  }

  componentDidMount = () => {
    this.sessionWatcher = registerEvent('session', ({time = this.time}) => this.time = time.substr(0, 5));
    this.blotterWatcher = registerEvent('blotter', line => {
      if (!line.time) line.time = this.time;
      const entries = [...this.state.entries, line];
      this.setState({entries, playSound: false}, () => {
        this.scrollToBottom({behavior: 'smooth'});
        this.setState({playSound: true});
      });
      setData('blotter', entries);
      fireEvent('sound', {sound: 'deal.mp3'});
    });
    setTimeout(this.scrollToBottom, 250);
  }

  componentWillUnmount = () => unRegisterEvent(this.blotterWatcher, this.sessionWatcher);

  scrollToBottom = (how) => this.messagesEnd.scrollIntoView(how);

  render () {
    const {entries = []} = this.state;
    return (
      <div className="inner-container" style={{overflowY: 'hidden'}}>
        <Title>Blotter</Title>
        <div style={localStyle.blotterBox}>
          <table>
            <tbody>
              {entries.map((entry, index) => (
                <tr key={index} className="noselect" style={{fontWeight: index === entries.length - 1 ? 600 : 400, color: getCol(entry.amount), backgroundColor: getBackgroundCol(entry.market), borderBottom: '1px solid #EEE'}}>
                  <td style={{paddingRight: 4, fontSize: 15}} className='mono' valign="top">{entry.time}</td>
                  <td style={{paddingTop: 2}} valign="top">{entry.text}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div ref={(el) => this.messagesEnd = el} />
        </div>
        {/* {playSound ? <Sound sound='deal' onDone={() => this.setState({playSound: false})}/> : null} */}
      </div>
    );
  }
}

const getCol = (amount) => {
  if (amount > 0) return '#008';
  if (amount < 0) return '#800';
  return '#000';
};

const getBackgroundCol = (market) => {
  if (!market) return '#FFF';
  if (parseInt(market) >= 5) return '#AFD';
  if (parseInt(market) >= 4) return '#DFA';
  if (parseInt(market) >= 3) return '#FFD';
  if (parseInt(market) >= 2) return '#FEF';
  return '#FFF';
};

const localStyle = {
  blotterBox: {flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#FFF', padding: 4, overflowY: 'scroll'}
};
