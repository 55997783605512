import React from 'react';
import Title from '../components/Title';
import './chat.css';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {getData, setData} from 'lib/data';
import md5 from 'lib/md5';
import {send} from 'lib/socket';

export default class Chat extends React.Component {
  constructor (props) {
    super(props);
    const system = getData('system', {});
    this.time = system.start || '07:00';
    const entries = getData('chat') || [];
    this.state = {entries, chatText: ''};
    this.user = getData('user', {dealer: 1});
    this.dealer = getData('dealer', {});
  }

  componentDidMount = async () => {
    this.mounted = true;
    // const audio = new Audio('https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3');
    // setTimeout(audio.play, 2000);
    // if (!this.state.gotData) {
    //   const resp = await get('api/data/chat');
    //   let {banks = [], custs = [], chats = []} = resp || {};
    //   this.bankNames = [...banks, ...custs].map(item => {
    //     const [first, last] = item.name.split(' ');
    //     return `${first}${last ? ' ' + last.substr(0, 1) : ''}`; // + '.'
    //   });
    //   this.chats = chats;
    // }

    this.sessionWatcher = registerEvent('session', ({time = this.time}) => this.time = time.substr(0, 5));
    this.chatWatcher = registerEvent('chat', ({dealer, from, message}) => this.addChatLine(from, message));
    setTimeout(this.scrollToBottom, 100);
  }

  componentWillUnmount = () => {
    this.mounted = false;
    unRegisterEvent(this.sessionWatcher, this.chatWatcher);
  }

  onType = (e) => this.setState({chatText: e.target.value});

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.state.chatText) {
      // this.addChatLine('Me', this.state.chatText);
      send('chat', {message: this.state.chatText});
      // setTimeout(this.reply, 2000 + Math.floor(Math.random() * 2000));
    }
  }

  addChatLine = (who, chat) => {
    if (chat && this.mounted) {
      let {entries} = this.state;
      chat = chat.replace('$name', this.dealer.nickname || this.dealer.name);
      entries.push({time: this.time, who, chat});
      this.setState({entries, chatText: ''}, () => this.scrollToBottom({behavior: 'smooth'}));
      setData('chat', entries);
      fireEvent('sound', {sound: 'chat.mp3'});
    }
  }

  // reply = () => {
  //   if (this.mounted && this.chats && this.bankNames) {
  //     const chat = this.chats[Math.floor(Math.random() * this.chats.length)];
  //     const who = this.bankNames[Math.floor(Math.random() * this.bankNames.length)];
  //     this.addChatLine(who, chat);
  //   }
  // }

  scrollToBottom = (how) => this.messagesEnd.scrollIntoView(how);

  render () {
    const {entries = [], chatText} = this.state;
    return (
      <div className="inner-container" style={{overflowY: 'hidden'}}>
        <Title>Chat</Title>
        <div style={localStyle.chatBox}>
          <div style={localStyle.chatBoxInner}>
            <table style={{flex: 1}}>
              <tbody>
                {entries.map((entry, index) => {
                  const isBold = index === entries.length - 1 || entry.who === 'Admin' || entry.who === this.dealer.name || entry.who === this.dealer.nickname;
                  return (
                    <tr key={index} className="noselect chat-tr" style={{fontWeight: isBold ? 600 : 400, color: getColor(entry.who.name || entry.who, this.dealer.nickname)}}>
                      <td style={{paddingRight: 4, fontSize: 15}} className='mono'>{entry.time}</td>
                      <td style={{paddingRight: 4, paddingTop: 2, width: '100%'}}>{entry.who.name || entry.who}: {entry.chat}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div ref={(el) => this.messagesEnd = el} />
          </div>
          <div style={localStyle.inputDiv}>
            <input style={localStyle.input} placeholder='Enter chat...' value={chatText} onChange={this.onType} onKeyDown={this.handleKeyPress}/>
          </div>
        </div>
      </div>
    );
  }
}

function getColor (name, myname) {
  if (name === 'Me' || name === myname) return '#000';
  if (name === 'Admin') return '#004';
  let hash = md5(name);
  let color = '';
  for (var i = 0; i < 3; i++) {
    let col = hash.substr(i * 2, 2);
    col = Math.max(Math.round(parseInt(col, 16) * 0.8), 16);
    color += col.toString(16);
  }
  return '#' + color;
}

const localStyle = {
  chatBox: {flex: 1, display: 'flex', flexDirection: 'column', padding: 4, alignItems: 'stretch', overflowY: 'hidden', backgroundColor: '#FFF'},
  chatBoxInner: {flex: 1, flexDirection: 'column', overflowY: 'scroll'},
  inputDiv: {backgroundColor: '#F0F', marginTop: 4},
  input: {width: '100%', padding: 4}
};
