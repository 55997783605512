/* eslint no-unused-vars: 0 */
import cookie from 'react-cookies';
import {decrypt, encrypt} from './encryption';

let db = readFromStorage();
let watchers = [];

export function setDataObj (newObj) {
  db = {...db, ...newObj};
  writeToStorage(db);
}

export function setData (key, val, isCookie, cookieOptions = {}) {
  const oldStringified = JSON.stringify(db[key]);
  const stringified = JSON.stringify(val);
  db[key] = val;

  if (stringified !== oldStringified && watchers[key]) {
    for (const id in watchers[key]) watchers[key][id](val);
  }
  if (isCookie) cookie.save(key, val, {path: '/', ...cookieOptions});
  else writeToStorage(db);
}

export function getData (key, defaultVal) {
  if (db[key]) return db[key];
  const val = cookie.load(key);
  try {
    if (val) return JSON.parse(val);
  } catch (err) {
    return val;
  }

  return defaultVal;
}

export const debugData = () => console.log(db);

export function addTimedEntry (time, key, value) {
  const [h, m] = time.split(':').map(i => parseInt(i, 10));
  const mins = (h - 7) * 60 + m;
  let dataSeries = getData(key, []);
  dataSeries[mins] = value;
  setData(key, dataSeries);
}

export function clearAllData () {
  db = {};
  eraseStorage();
}

export function deleteData (key, newVal = '', isCookie) {
  if (db[key]) delete db[key];
  if (watchers[key]) {
    for (const id in watchers[key]) watchers[key][id](newVal);
  }
  if (isCookie) cookie.remove(key);
}

export function addDataWatcher (table, cb) {
  if (!watchers[table]) watchers[table] = {};
  const id = new Date().getTime() + Math.round(Math.random() * 1000000);
  watchers[table][id] = cb;
  return id;
}

export function removeDataWatcher (...ids) {
  for (const id of ids) {
    for (const table in watchers) {
      if (watchers[table][id]) delete watchers[table][id];
    }
  }
}

function readFromStorage () {
  const encrypted = localStorage.getItem('db_enc');
  if (encrypted) return JSON.parse(decrypt(encrypted));
  else return JSON.parse(localStorage.getItem('db') || '{}');
}

function writeToStorage (db) {
  localStorage.setItem('db', JSON.stringify(db)); // if (process.env.DEV)
  const encrypted = encrypt(JSON.stringify(db));
  localStorage.setItem('db_enc', encrypted);
}

function eraseStorage () {
  localStorage.removeItem('db');
  localStorage.removeItem('db_enc');
}
