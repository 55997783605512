import React from 'react';
import Title from '../components/Title';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {doDeal, formatPrice} from 'lib/markets';
import {addTimedEntry} from 'lib/data';

export default class Market extends React.Component {
  state = {b: 0, o: 0, ba: 0, oa: 0, bidColor: '#FFF', offerColor: '#FFF', bidRandomDelta: 0};

  constructor (props) {
    super(props);
    this.marketName = this.props.market === 0 ? 'Broker' : 'Market';
    this.sessionStatus = 'wait';
  }

  componentDidMount = () => {
    this.mounted = true;
    this.marketWatcher = registerEvent('market_' + this.props.market, this.updateMarket);
    this.minuteWatcher = registerEvent('minute', (time) => addTimedEntry(time, 'market_' + this.props.market, (this.state.b + this.state.o) / 2));
    this.resetShout();
  }

  resetShout = (shoutNow) => {
    if (this.props.market === 0) {
      clearInterval(this.shoutTimer);
      this.shoutTimer = setInterval(this.shoutPrices, 10000);
      if (shoutNow) this.shoutPrices();
    }
  }

  shoutPrices = () => {
    let sounds = [];
    if (this.prices.ba && this.prices.oa) sounds = [`s_${this.prices.b % 100}.mp3`, `s_${this.prices.o % 100}.mp3`];
    else if (this.prices.ba && !this.prices.oa) sounds = [`s_${this.prices.b % 100}.mp3`, 's_bid.mp3'];
    else if (!this.prices.ba && this.prices.oa) sounds = [`s_${this.prices.o % 100}.mp3`, 's_offerd.mp3'];
    if (this.props.enabled && sounds.length) fireEvent('sound', {sounds});
  }

  updateMarket = (prices) => {
    clearTimeout(this.whiteTimer);
    const changed = !this.prices || (prices.b && prices.b !== this.prices.b) || (prices.o && prices.o !== this.prices.o);
    this.prices = {...(this.prices || {}), ...prices};
    const bidDelta = prices.b - this.state.b;
    const bidColor = bidDelta > 0 ? '#88F' : bidDelta < 0 ? '#F88' : '#FFF';
    const offerDelta = prices.o - this.state.o;
    const offerColor = offerDelta > 0 ? '#88F' : offerDelta < 0 ? '#F88' : '#FFF';
    this.setState({...prices, bidColor, offerColor});
    this.whiteTimer = setTimeout(() => this.setState({bidColor: '#FFF', offerColor: '#FFF'}), 500);
    if (changed) this.resetShout(true);
  }

  componentWillUnmount = () => {
    clearTimeout(this.whiteTimer);
    clearTimeout(this.deltaTimer);
    clearInterval(this.shoutTimer);
    unRegisterEvent(this.marketWatcher, this.minuteWatcher);
  }

  onBuy = async () => {
    if (this.state.oa <= 0 || !this.props.enabled) return;
    if (this.props.onBuy) this.props.onBuy(5);
    this.setState({buying: true});
    await doDeal({market: this.props.market, price: this.state.o, amount: 5, type: 'market'});
    this.setState({buying: false});
    fireEvent('blotter', {text: `Bought 5 Crystals from ${this.marketName} at ${formatPrice(this.state.o)}`, amount: 5, market: this.props.market});
  }

  onSell = async () => {
    if (this.state.ba <= 0 || !this.props.enabled) return;
    if (this.props.onSell) this.props.onSell(5);
    this.setState({selling: true});
    await doDeal({market: this.props.market, price: this.state.b, amount: -5, type: 'market'});
    this.setState({selling: false});
    fireEvent('blotter', {text: `Sold 5 Crystals to ${this.marketName} at ${formatPrice(this.state.b)}`, amount: -5, market: this.props.market});
  }

  formatMarket = (price) => {
    let decs = 2;
    let newPrice = (price || 0) / Math.pow(10, decs);
    newPrice = newPrice.toFixed(decs);
    if (this.props.market === 0) newPrice = newPrice.substr(0, newPrice.length - 2) + 'XX';
    return newPrice;
  }

  render () {
    const {b, o, ba, oa} = this.state;
    return (
      <div className="inner-container">
        <Title>{this.props.title}</Title>
        <div style={localStyle.marketBox}>
          <div style={localStyle.row}>
            <div style={{...localStyle.box, backgroundColor: this.state.bidColor}}>{this.formatMarket(b)}</div>
            <div style={{...localStyle.box, backgroundColor: this.state.offerColor}}>{this.formatMarket(o)}</div>
          </div>
          <div style={localStyle.row}>
            <div style={localStyle.box}>{ba || 0}</div>
            <div style={localStyle.box}>{oa || 0}</div>
          </div>
          <div style={localStyle.row}>
            <Button onClick={this.onSell} disabled={this.state.selling} style={{backgroundColor: '#F30'}}>SELL</Button>
            <Button onClick={this.onBuy} disabled={this.state.buying} style={{backgroundColor: '#08F'}}>BUY</Button>
          </div>
        </div>
      </div>
    );
  }
}

const Button = (props) => {
  if (props.disabled) return <button className="btn" onClick={props.onClick} style={{...localStyle.button, backgroundColor: '#999'}} disabled>{props.children}</button>;
  return <button className="btn" onClick={props.onClick} style={{...localStyle.button, ...props.style}}>{props.children}</button>;
};

const localStyle = {
  marketBox: {flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'stretch', backgroundColor: '#FFF', padding: 4},
  row: {display: 'flex', flexDirection: 'row', justifyContent: 'space-around'},
  box: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, fontWeight: 500},
  button: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, height: 30, padding: 0, fontWeight: 600}
};
