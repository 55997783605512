import React from 'react';
import {post} from 'lib/comms';
import {setData, getData} from 'lib/data';
import CenterText from 'components/layout/CenterText';
import './pages.css';

export default class LoginPage extends React.Component {
  state = {name: '', code: '', errorText: '', loading: true};

  componentDidMount = async () => {
    const token = getData('ntstoken');
    if (token) {
      const result = await post('api/login', {token});
      if (result.dealer) this.props.onLogin(result.dealer);
      else this.setState({loading: false});
    } else this.setState({loading: false});
  }

  tryLogin = async () => {
    const {name = '', code = '', course = ''} = this.state;
    const result = await post('api/login', {name, code, course});
    if (result.token) setData('ntstoken', result.token, true);
    if (result.dealer) this.props.onLogin(result.dealer);
    if (result.courses) this.setState({courses: result.courses, course: result.courses[0]});
    if (result.message) this.setState({errorText: result.message});
  }

  render () {
    const {name, code, errorText, courses, loading} = this.state;
    const isPW = code.length && code.substr(0, 1) !== 'c';
    if (loading) return <CenterText text="Checking login..."/>;
    return (
      <div className="outer">
        <div className="full-center">
          <div className="card-body" style={{marginTop: '-100px'}}>
            <h4 className="card-title">Log in</h4>
            <hr/>
            <div className="row form-group">
              <label className="col-sm-5 control-label pt-1">Your name:</label>
              <div className="col-sm-7">
                <input type="text" className="form-control" value={name} onChange={e => this.setState({name: e.target.value, errorText: ''})} autoComplete="off" />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-sm-5 control-label pt-1">{code === '' ? 'Password/Course code:' : isPW ? 'Password:' : 'Course code:'}</label>
              <div className="col-sm-7">
                <input type={isPW ? 'password' : 'text'} className="form-control" value={code} onChange={e => this.setState({code: e.target.value, errorText: ''})} autoComplete="off" disabled={courses ? 'disabled' : ''}/>
                {errorText ? <span className="errortext">{errorText}</span> : null}
              </div>
            </div>
            {courses ? (
              <div className="row form-group">
                <label className="col-sm-5 control-label pt-1">Select sim:</label>
                <div className="col-sm-7">
                  <select className="form-control" onChange={e => this.setState({course: e.target.value})}>
                    {courses.map(course => <option key={course.file} value={course.file}>{course.name} ({course.file.replace('.json', '')})</option>)}
                  </select>
                </div>
              </div>
            ) : null}
            <hr/>
            <div className="row form-group mb-0">
              <div className="col-lg-12">
                <button className="btn btn-primary btn-block" onClick={this.tryLogin}>Log in</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
