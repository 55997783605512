import React from 'react';
import Header from 'components/layout/Header';
import CenterText from 'components/layout/CenterText';
import MarketBox from './dealerComponents/Markets/MarketBox';
import LineBox from './dealerComponents/Lines/LineBox';
import BlotterBox from './dealerComponents/Blotter/BlotterBox';
import {connect} from 'lib/socket';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {clearAllData, setData} from 'lib/data';
import './DealerPage.css';
import NeedleBox from './dealerComponents/Needles/NeedlesBox';
import Graph from './dealerComponents/Graph/Graph';
import News from './dealerComponents/News/News';
import Sound from '../components/Sound';

// import {addDataWatcher, removeDataWatcher} from 'lib/data';

export default class AdminPage extends React.Component {
  state = {me: null, loading: true, connected: false, session: {}, time: ''};

  componentDidMount = async () => {
    this.connectWatcher = registerEvent('connected', connected => this.setState({connected}));
    // this.msgWatcher = registerEvent('message', this.processMessage);
    this.sessionWatcher = registerEvent('session', (session) => {
      if (session.status === 'end') clearAllData();
      const time = session.time ? session.time.substr(0, 5) : '';
      if (time && time !== this.state.time) {
        setData('time', time);
        fireEvent('minute', time);
        this.setState({session, time});
      } else this.setState({session});
    });
    connect();
    this.setState({loading: false});
    setTimeout(() => this.setState({left: 300}), 1000);
  }

  componentWillUnmount = () => unRegisterEvent(this.connectWatcher, this.sessionWatcher);

  render () {
    const {loading, connected, session} = this.state;
    const enabled = (session.status === 'on');
    const frozen = (session.status === 'frozen');
    const ended = (session.status === 'end');
    const blurText = frozen ? 'Session Frozen' : ended ? 'Session Ended' : null;
    if (loading) return <CenterText header={{connected, title: ''}} text=''/>;
    // if (session.status === 'frozen') return <CenterText header={{connected, title: 'Dealer', session}} text='Session Frozen'/>;
    // if (session.status === 'end') return <CenterText header={{connected, title: 'Dealer', session}} text='Session Finished'/>;
    return (
      <div className="outer">
        <Header connected={connected} title='Dealer' session={session}/>
        <div className={`dealer-container ${blurText ? 'blurred' : ''}`}>
          <div className="dealer-top">
            <MarketBox enabled={enabled}/>
            <LineBox enabled={enabled}/>
            <BlotterBox/>
            <NeedleBox enabled={enabled}/>
          </div>
          <div className="dealer-bottom" style={{overflowY: 'hidden'}}>
            <News enabled={enabled}/>
            <Graph/>
          </div>
          <Sound/>
        </div>
        {blurText ? <div className="total-blur noselect">{blurText}</div> : null}

        {/* <div class='animated' style={{position: 'absolute', width: 200, height: 20, top: 100, left, backgroundColor: 'red'}}/> */}
      </div>
    );
  }
}
