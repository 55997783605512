import React from 'react';
import {FlexibleXYPlot, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis} from 'react-vis';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';
import MenuBar from 'admin/adminComponents/MenuBar/MenuBar';
import 'react-vis/dist/style.css';

export default class Graph extends React.Component {
  state = {pageIndex: 0};

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.tabListener = registerEvent('TAB', ({currentTabIndex}) => currentTabIndex === 0 && this.resize()); // when switch tab we also need to redraw
  }

  resize = () => {
    clearTimeout(this.showChart);
    this.setState({resetChart: true});
    this.showChart = setTimeout(() => this.setState({resetChart: false}), 100);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timerId);
    window.removeEventListener('resize', this.resize);
    unRegisterEvent(this.tabListener);
  }

  render () {
    const {pageIndex} = this.state;
    const serieses = getSeriesData(this.props.dealers, pageIndex);
    return (
      <div className="p-2" style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
        <MenuBar onChange={(pageIndex) => this.setState({pageIndex})} btns={['Position', 'Total Profit', 'Profit']} tabColor='#FFF'/>
        <div style={localStyle.graphBox}>
          {this.state.resetChart ? null : <Chart serieses={serieses} pageIndex={pageIndex}/>}
        </div>
      </div>
    );
  }
}

const getSeriesData = (dealers, pageIndex) => {
  if (pageIndex === 0) return [{color: '#000080', data: dealers.map(dealer => ({x: dealer.name, y: dealer.position || 0, y0: 0}))}];
  if (pageIndex === 1) return [{color: '#600', data: dealers.map(dealer => ({x: dealer.name, y: (dealer.realised || 0) + (dealer.unrealised || 0), y0: 0}))}];
  if (pageIndex === 2) return [{color: '#800080', data: dealers.map(dealer => ({x: dealer.name, y: dealer.unrealised || 0, y0: 0}))}, {color: '#008000', data: dealers.map(dealer => ({x: dealer.name, y: dealer.realised || 0, y0: 0}))}];
};

const Chart = ({serieses, pageIndex}) => { // serieses is an array of {color, data}
  const margin = pageIndex === 0 ? 5 : 1;
  return (
    <FlexibleXYPlot yDomain={getMinMaxForData(serieses, margin)} xType="ordinal" margin={{left: 50, right: 10, top: 10, bottom: 40}}>
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis />
      <YAxis />
      {serieses.map((series, index) => <VerticalBarSeries key={index} data={series.data} color={series.color}/>)}
    </FlexibleXYPlot>
  );
};

const localStyle = {
  graphBox: {flex: 1, display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 4, margin: 4, marginTop: 0}
};

const getMinMaxForData = (data, margin = 5) => {
  let [min, max] = [-5, 5];
  data.forEach(series => {
    min = series.data.reduce((t, bar) => Math.min(bar.y, t), min);
    max = series.data.reduce((t, bar) => Math.max(bar.y, t), max);
  });
  return [min - margin, max + margin];
};
