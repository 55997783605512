import React from 'react';
import Header from 'components/layout/Header';
import CenterText from 'components/layout/CenterText';
// import {get} from 'lib/comms';
import {connect} from 'lib/socket';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import SessionBar from './adminComponents/SessionBar/SessionBar';
import MenuBar from './adminComponents/MenuBar/MenuBar';
import Dealers from './pages/dealers/DealersTab';
import Prices from './pages/Prices';
import {getData} from 'lib/data';
import './AdminPage.css';

// import {addDataWatcher, removeDataWatcher} from 'lib/data';

export default class AdminPage extends React.Component {
  constructor (props) {
    super(props);
    const markets = getData('markets', []);
    this.state = {loading: true, connected: false, session: {}, currentTabIndex: 0, markets};
  }

  processMessage = (message) => {
    console.log('message', message);
  }

  componentDidMount = async () => {
    this.connectWatcher = registerEvent('connected', connected => this.setState({connected}));
    this.msgWatcher = registerEvent('message', this.processMessage);
    this.sessionWatcher = registerEvent('session', (session) => this.setState({session}));
    this.marketWatcher = registerEvent('markets', (markets) => this.setState({markets}));

    connect();
    this.setState({loading: false});
  }

  componentWillUnmount = () => unRegisterEvent(this.connectWatcher, this.msgWatcher, this.sessionWatcher);

  selectTab = (currentTabIndex) => {
    fireEvent('TAB', {currentTabIndex});
    this.setState({currentTabIndex});
  }

  render () {
    const {loading, connected, session, currentTabIndex, markets} = this.state;
    if (loading) return <CenterText header={{connected, title: 'Admin'}} text='Establishing system details...'/>;
    return (
      <div className="outer">
        <Header connected={connected} title='Admin' session={session}/>
        <div className="admin-container">
          <SessionBar session={session}/>
          <MenuBar onChange={this.selectTab} btns={['Dealers', 'Prices', 'News', 'System']} backgroundColor='#AEAEEB' tabColor='#FCAAAA'/>
          <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
            <Dealers isVisible={currentTabIndex === 0} market={markets[1] || {}}/>
            <Prices isVisible={currentTabIndex === 1}/>
          </div>
        </div>
      </div>
    );
  }
}
