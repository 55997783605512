import React from 'react';
import Title from '../components/Title';
import {formatPrice, addRealised, calcDeal} from 'lib/markets';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {getData, setData} from 'lib/data';

import './needles.css';

export default class NeedlesWafers extends React.Component {
  constructor (props) {
    super(props);
    const {position = 0, avePrice = 0} = getData('nonearth_' + props.market, {});
    this.state = {position, avePrice};
  }

  componentDidMount = () => {
    this.mounted = true;
    this.marketWatcher = registerEvent('market_' + this.props.market, this.updateMarket);
  }

  updateMarket = (prices) => {
    clearTimeout(this.whiteTimer);
    prices.o = prices.o || (prices.b + Math.round(Math.random() * 2) + 6);
    const bidDelta = prices.b - this.state.b;
    const bidColor = bidDelta > 0 ? '#88F' : bidDelta < 0 ? '#F88' : '#FFF';
    const offerDelta = prices.o - this.state.o;
    const offerColor = offerDelta > 0 ? '#88F' : offerDelta < 0 ? '#F88' : '#FFF';
    this.setState({...prices, bidColor, offerColor});
    this.whiteTimer = setTimeout(() => this.setState({bidColor: '#FFF', offerColor: '#FFF'}), 500);
  }

  getInfo = () => ({c: this.state.c, c23: this.state.c23, position: this.state.position, avePrice: this.state.avePrice});;

  componentWillUnmount = () => {
    clearTimeout(this.whiteTimer);
    unRegisterEvent(this.marketWatcher);
  }

  onDeal = async (amount) => {
    if (!this.props.enabled) return;
    let {position = 0, avePrice = 0, b, o, ba, oa} = this.state;
    const price = amount > 0 ? o : b;
    const available = amount > 0 ? oa : ba;
    if (available <= 0) return;

    const dealResults = calcDeal(amount, price, position, avePrice);
    if (dealResults.realised) addRealised(dealResults.realised, this.props.market);

    let newState = {position: dealResults.position, avePrice: dealResults.avePrice};
    setData('nonearth_' + this.props.market, newState);
    if (amount > 0) newState.oa = oa - 5;
    else newState.ba = ba - 5;
    this.setState(newState);
    fireEvent('sound', {sound: 'deal.mp3'});
  }

  clearPosition = () => {
    this.setState({position: 0, avePrice: 0});
    setData('nonearth_' + this.props.market, {position: 0, avePrice: 0});
  }

  render () {
    const {b, o, ba, oa, c, c23, position = 0, avePrice = 0} = this.state;
    const {title = 'Planet B', minsToGo = 0, transportMins = 60, other, lot, otherLot} = this.props;
    return (
      <div className="inner-container" style={{overflowY: 'hidden'}}>
        <Title rightTitle={`${position} @ ${avePrice.toFixed(2)}`}>{title}</Title>
        <div style={localStyle.marketBox}>
          <div style={localStyle.row}>
            <div style={{...localStyle.box, backgroundColor: this.state.bidColor}}>{formatPrice(b || 0)}</div>
            <div style={{...localStyle.box, backgroundColor: this.state.offerColor}}>{formatPrice(o || 0)}</div>
          </div>
          <div style={localStyle.row}>
            <div style={localStyle.box}>{ba || 0}</div>
            <div style={localStyle.box}>{oa || 0}</div>
          </div>
          <div style={localStyle.row}>
            <Button onClick={() => this.onDeal(-5)} disabled={this.state.selling} style={{backgroundColor: '#F30'}}>SELL</Button>
            <Button onClick={() => this.onDeal(5)} disabled={this.state.buying} style={{backgroundColor: '#08F'}}>BUY</Button>
          </div>
          <div style={localStyle.row}>
            <div style={localStyle.box2}>
              <div style={localStyle.box2Labels}>
                <div style={{paddingLeft: 4}}>Earth: {(c || 0).toFixed(1)} ({lot})</div>
                <div style={{paddingRight: 4}}>{other}: {(c23 || 0).toFixed(1)} ({otherLot})</div>
              </div>
              <div style={{width: '100%', backgroundColor: '#CCC', flex: 1, height: 5}}>
                <div style={{width: 100 * minsToGo / transportMins + '%', backgroundColor: (minsToGo < 10 ? '#F00' : '#00F'), height: 5}}> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Button = (props) => {
  if (props.disabled) return <button className="btn" onClick={props.onClick} style={{...localStyle.button, backgroundColor: '#999'}} disabled>{props.children}</button>;
  return <button className="btn" onClick={props.onClick} style={{...localStyle.button, ...props.style}}>{props.children}</button>;
};

const localStyle = {
  marketBox: {flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'stretch', backgroundColor: '#FFF', padding: 4},
  row: {display: 'flex', flexDirection: 'row', justifyContent: 'space-around'},
  box: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, display: 'flex', alignItems: 'center', justifyContent: 'center'},
  box2: {flex: 1, textAlign: 'center', border: '1px solid #000', marginLeft: 12, marginRight: 10},
  box2Labels: {flex: 1, textAlign: 'center', fontSize: 14, fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'space-between'},
  button: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, height: 30, padding: 0, fontWeight: 600}
};
