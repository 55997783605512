import React from 'react';
import Line from './Line';
import Title from '../components/Title';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';
import {getData} from 'lib/data';

import './lines.css';

const linesCount = 16;

export default class LineBox extends React.Component {
  constructor (props) {
    super(props);
    this.refList = Array(linesCount);

    this.currentRCallIndex = -1;
    this.calls = getData('calls', {rcalls: [], acalls: []});
    this.custs = getData('custs', []);

    let banks = getData('banks', []);
    this.banksCount = banks.length;
    while (banks.length < linesCount) banks.push({});
    this.state = {banks, buyBias: 0};
  }

  componentDidMount = async () => {
    this.mounted = true;
    const system = getData('system', {});
    this.minuteWatcher = registerEvent('minute', this.updateMinute);
    this.updateMinute(system.start || '00:00');
  }

  componentWillUnmount = () => {
    this.mounted = false;
    if (this.rcallTimer) clearInterval(this.rcallTimer);
    if (this.initialTimer) clearTimeout(this.initialTimer);
    unRegisterEvent(this.minuteWatcher);
  }

  doRCall = () => {
    let isBank = Math.random() < 0.5;
    if (isBank) {
      let line;
      let i = 0;
      while (!line && i < 20) {
        const randomBank = Math.floor(Math.random() * this.banksCount);
        line = this.refList[randomBank];
        if (line.getStatus() !== 'idle') line = null;
        i++;
      }
      if (line && this.props.enabled) line.callin();
      if (!line) isBank = false;
    }

    if (!isBank && this.custs) {
      const cust = this.custs[Math.floor(Math.random() * this.custs.length)];
      const line = this.refList.slice(this.banksCount).find(l => l.getStatus() === 'idle');
      if (line && this.props.enabled) line.callin({caller: cust.name, image: cust.image, amount: 5, buy: Math.random() < this.state.buyBias / 100, at: Math.abs(this.state.buyBias / 50)});
    }
  }

  doACall = (call) => {
    const line = this.refList.slice(this.banksCount).find(l => l.getStatus() === 'idle');
    if (line && call && this.props.enabled) setTimeout(line.callin(call), Math.round(Math.random() * 3000)); // slight random delay
  }

  // check for update in rcalls or an acall
  updateMinute = (time) => {
    const foundIndex = (this.calls.rcalls || []).reduce((t, call, i) => call.time <= time ? i : t, 0);
    if (foundIndex !== this.currentRCallIndex && this.calls.rcalls) {
      this.currentRCallIndex = foundIndex;
      clearInterval(this.rcallTimer);
      clearTimeout(this.initialTimer);
      if (this.calls.rcalls[this.currentRCallIndex] && this.calls.rcalls[this.currentRCallIndex].enabled) {
        this.rcallTimer = setInterval(this.doRCall, this.calls.rcalls[this.currentRCallIndex].secs * 1000);
        this.initialTimer = setTimeout(this.doRCall, this.calls.rcalls[this.currentRCallIndex].secs * 500);
      }
    }

    if (this.calls.bias && this.calls.bias.length) {
      const foundBiasIndex = (this.calls.bias || []).reduce((t, bias, i) => bias.time <= time ? i : t, 0);
      const {buyBias} = this.calls.bias[foundBiasIndex];
      if (buyBias !== this.state.buyBias) this.setState({buyBias});
    }

    const acall = (this.calls.acalls || []).find(call => call.time === time);
    if (acall && this.props.enabled) this.doACall(acall);
  }

  render () {
    const {enabled} = this.props;
    const {banks = [], buyBias} = this.state;
    const banks1 = banks.filter((bank, index) => index % 2 === 0);
    const banks2 = banks.filter((bank, index) => index % 2);
    return (
      <div style={localStyle.linesOuter}>
        <div className="inner-container">
          <Title>Lines</Title>
          <div style={localStyle.linesCols}>
            <div style={localStyle.linesCol}>{banks1.map((bank, index) => <Line bank={bank} key={index} buyBias={buyBias} enabled={enabled} ref={(ref) => this.refList[index * 2] = ref}></Line>)}</div>
            <div style={localStyle.linesCol}>{banks2.map((bank, index) => <Line bank={bank} key={index} buyBias={buyBias} enabled={enabled} ref={(ref) => this.refList[index * 2 + 1] = ref}></Line>)}</div>
          </div>
        </div>
      </div>
    );
  }
}

const localStyle = {
  linesOuter: {minWidth: 400, display: 'flex', flexDirection: 'column', flex: 1},
  linesCols: {display: 'flex', flexDirection: 'row', flex: 1},
  linesCol: {display: 'flex', flexDirection: 'column', flex: 1}
};
