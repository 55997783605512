import React from 'react';

import './Header.css';
import {AiOutlineFullscreenExit, AiOutlineFullscreen} from 'react-icons/ai';
import {BiVolumeFull, BiVolumeMute} from 'react-icons/bi'; // https://react-icons.github.io/react-icons
import {getData, setData} from 'lib/data';

export default class Header extends React.Component {
  constructor (props) {
    super(props);
    this.state = {mute: getData('mute', false), connected: true};
    this.dealer = getData('dealer', {});
  }

  componentDidMount = () => window.addEventListener('resize', this.resize);

  resize = () => this.setState({isFullScreen: document.fullscreenElement});

  componentWillUnmount = () => window.removeEventListener('resize', this.resize);

  toggleFullscreen = () => {
    if (document.fullscreenElement) document.exitFullscreen();
    else {
      const element = document.documentElement;
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }

  toggleMute = () => {
    const mute = !this.state.mute;
    this.setState({mute});
    setData('mute', mute);
  }

  render () {
    const {isFullScreen, mute} = this.state;
    const {connected, session = {}, title} = this.props;
    let leftText = '';
    if (session.status === 'wait') leftText = 'Waiting to start...';
    if (session.status === 'on' && session.time) leftText = `Time: ${session.time.substr(0, 5)}`;
    if (session.status === 'frozen') leftText = 'Session frozen';
    if (session.status === 'end') leftText = 'Session finished';
    return (
      <div className="header-container">
        <div className="header-time">{leftText}</div>
        <div>{this.dealer.name || title}</div>
        <div className="header-right">
          <button onClick={this.toggleMute} className="mx-1 p-0 blank-button">{mute ? <BiVolumeMute size={26}/> : <BiVolumeFull size={26}/>}</button>
          <button onClick={this.toggleFullscreen} className="mx-1 p-0 blank-button">{isFullScreen ? <AiOutlineFullscreenExit size={26}/> : <AiOutlineFullscreen size={26}/>}</button>
          <div className={connected ? 'header-connnection header-connected' : 'header-connnection'}></div>
        </div>
      </div>
    );
  }
}
