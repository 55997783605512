import React from 'react';
import './MenuBar.css';

export default class MenuBar extends React.Component {
  state = {currentIndex: this.props.index || 0}

  selectPage = (index) => {
    this.setState({currentIndex: index});
    if (this.props.onChange) this.props.onChange(index);
  }

  render () {
    const {btns, backgroundColor, tabColor} = this.props;
    const {currentIndex} = this.state;
    return (
      <div className="menu-bar" style={{backgroundColor}}>
        {btns.map((btn, index) => <button key={btn} style={{backgroundColor: index === currentIndex ? tabColor : ''}} className='btn btn-sm menu-btn' onClick={() => this.selectPage(index)} >{btn}</button>)}
      </div>
    );
  }
}
