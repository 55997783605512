import React from 'react';
import Title from '../components/Title';
import {registerEvent, unRegisterEvent, fireEvent} from 'lib/eventlinks';
import {formatPrice, doDeal} from 'lib/markets';

export default class Working extends React.Component {
  state = {bid: '', offer: '', bidAmount: 10, offerAmount: 10, actualBid: 0, actualOffer: 0, working: false}

  constructor (props) {
    super(props);
    this.maxSpread = 16;
    this.maxDist = 10;
    this.maxAmount = 50;
  }

  componentDidMount = () => {
    this.mounted = true;
    this.marketWatcher = registerEvent('market_1', (market) => this.setState({market}));
    this.dealTimer = setInterval(this.checkDeal, 2000);
  }
  componentWillUnmount = () => {
    this.mounted = false;
    unRegisterEvent(this.marketWatcher);
    clearInterval(this.dealTimer);
  }

  checkDeal = () => {
    let {working, bidAmount, offerAmount, actualBid, actualOffer, market = {}} = this.state;
    if (working) {
      const buyChance = 50;
      const isBuying = Math.random() * 100 < buyChance; // chance of market buying from me
      let dealAmount = 0;
      if (isBuying && offerAmount && market.o && actualOffer <= market.o) dealAmount = -5;
      if (!isBuying && bidAmount && market.b && actualBid >= market.b) dealAmount = 5;
      if (dealAmount !== 0) {
        const price = dealAmount > 0 ? actualBid : actualOffer;
        doDeal({market: 1, price, amount: dealAmount, type: 'worked'});
        if (dealAmount > 0) bidAmount = Math.max(bidAmount - Math.abs(dealAmount), 0);
        else offerAmount = Math.max(offerAmount - Math.abs(dealAmount), 0);

        fireEvent('blotter', {text: `Worked quote, we ${dealAmount > 0 ? 'bought' : 'sold'} 5 Crystals at ${formatPrice(price)}`, amount: dealAmount});

        const newState = (offerAmount > 0 || bidAmount > 0) ? {bidAmount, offerAmount} : {working: false, bidAmount: 10, offerAmount: 10};

        this.setState({...newState, doneDeal: dealAmount}, () => setTimeout(() => this.mounted && this.setState({doneDeal: 0}), 500));
      }
    }
  }

  onWork = () => {
    let {bid, bidAmount, offer, offerAmount, working, market, error} = this.state;
    if (working || !this.props.enabled) return this.setState({working: false});
    if (bid === '' || offer === '' || bidAmount === '' || offerAmount === '') return;
    const priceStart = getBigFig(market, bid);
    let actualBid = priceStart * 100 + parseInt(bid, 10);
    let actualOffer = priceStart * 100 + parseInt(offer, 10);

    if (bidAmount > 0 && offerAmount > 0) {
      if (actualOffer < actualBid) actualOffer += 100;
      if (actualOffer - actualBid > Math.max(this.maxSpread, market.o - market.b)) error = `Max spread is ${this.maxSpread} points`;
      if (market.b - actualBid > this.maxDist) error = `Quotes must be within ${this.maxDist} points of the market`;
      if (actualOffer - market.o > this.maxDist) error = `Quotes must be within ${this.maxDist} points of the market`;
    } else if (bidAmount > 0) {
      actualOffer = actualBid;
      if (market.b - actualBid > this.maxDist) error = `Quotes must be within ${this.maxDist} points of the market`;
    } else if (offerAmount > 0) {
      actualBid = actualOffer;
      if (actualOffer - market.o > this.maxDist) error = `Quotes must be within ${this.maxDist} points of the market`;
    } else error = 'Please enter a valid amount';

    this.setState({bid, offer});
    bidAmount = Math.min(5 * Math.ceil(bidAmount / 5), this.maxAmount);
    offerAmount = Math.min(5 * Math.ceil(offerAmount / 5), this.maxAmount);

    if (error) this.setState({error}, () => setTimeout(() => this.mounted && this.setState({error: null}), 1200));
    else {
      this.setState({bidAmount, offerAmount, working: true, actualBid, actualOffer});
    }
  }

  textChange = (field, value) => {
    let newState = {};
    newState[field] = value;
    if (value.length > 2 || isNaN(value) || value.indexOf('-') >= 0 || value.indexOf('.') >= 0) return;
    if (field === 'bid' && value.length === 2) {
      this.offerRef.focus();
      this.setState({bigFig: 359});
    }
    if (field === 'offer' && value.length === 2) this.bidAmountRef.focus();
    this.setState(newState);
  }

  handleKeyPress = (e, next) => {
    if (e.key === 'Tab') {
      if (next && this[next]) this[next].focus();
      e.preventDefault();
    }
    if (e.key === 'Enter') this.onWork();
  }

  didFocus = (e) => e.target.select();

  render () {
    const {market, bid, bidAmount, offer, offerAmount, working, actualBid, actualOffer, error, doneDeal} = this.state;
    if (error) return <ErrorBox error={error}/>;
    const priceStart = getBigFig(market, bid);
    let workingText = `${formatPrice(actualBid)}-${formatPrice(actualOffer, {decsOnly: true})}`;
    if (working) {
      if (bidAmount > 0 && offerAmount <= 0) workingText = `${formatPrice(actualBid)} bid`;
      else if (bidAmount <= 0 && offerAmount > 0) workingText = `${formatPrice(actualOffer)} offered`;
    }
    return (
      <div className="inner-container" style={{height: 150}}>
        <Title>Work a bid/offer</Title>
        <div style={{...localStyle.marketBox}} >
          <div style={localStyle.row}>
            <table style={localStyle.table}>
              <tbody>
                {working && !error && <tr height={40}><td colSpan={4} align="center" style={localStyle.workingContainer}>{workingText}</td></tr>}
                {!working && !error && (
                  <tr height={40}>
                    <td style={localStyle.bigFig}>{priceStart}.</td>
                    <td><input style={localStyle.box} ref={ref => this.bidRef = ref} onFocus={this.didFocus} value={bid} onChange={e => this.textChange('bid', e.target.value)} onKeyDown={e => this.handleKeyPress(e, 'offerRef')}/></td>
                    <td style={localStyle.minus}>-</td>
                    <td><input style={localStyle.box} ref={ref => this.offerRef = ref} onFocus={this.didFocus} value={offer} onChange={e => this.textChange('offer', e.target.value)} onKeyDown={e => this.handleKeyPress(e, 'bidAmountRef')}/></td>
                  </tr>
                )}
                <tr>
                  <td width={60}></td>
                  <td><input style={{...localStyle.box, backgroundColor: doneDeal > 0 ? '#F3F' : '#FFF'}} readOnly={working} ref={ref => this.bidAmountRef = ref} onFocus={this.didFocus} value={bidAmount} onChange={e => this.textChange('bidAmount', e.target.value)} onKeyDown={e => this.handleKeyPress(e, 'offerAmountRef')}/></td>
                  <td width={20}></td>
                  <td><input style={{...localStyle.box, backgroundColor: doneDeal < 0 ? '#F3F' : '#FFF'}} readOnly={working} ref={ref => this.offerAmountRef = ref} onFocus={this.didFocus} value={offerAmount} onChange={e => this.textChange('offerAmount', e.target.value)} onKeyDown={e => this.handleKeyPress(e, 'bidRef')}/></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style={{...localStyle.buttonRow}}>
          {working ? <Button onClick={this.onWork} style={{backgroundColor: '#F3F'}}>CANCEL</Button> : <Button onClick={this.onWork} style={{backgroundColor: '#0F9'}}>WORK</Button>}
        </div>
      </div>
    );
  }
}
const Button = (props) => {
  if (props.disabled) return <button className="btn" onClick={props.onClick} style={{...localStyle.button, backgroundColor: '#999'}} disabled>{props.children}</button>;
  return <button className="btn" onClick={props.onClick} style={{...localStyle.button, ...props.style}}>{props.children}</button>;
};

const ErrorBox = (props) => (
  <div className="inner-container" style={{height: 150}}>
    <Title>Work a bid/offer</Title>
    <div style={localStyle.errorBox}>
      Invalid quote: <br/>
      {props.error}
    </div>
  </div>
);

const getBigFig = (market, bid) => {
  const priceStart = market ? Math.floor(market.b / 100) : 361;
  if (bid && market) {
    const tryBid = priceStart * 100 + parseInt(bid, 10);
    if (tryBid - market.b > 50) return priceStart - 1;
    if (market.b - tryBid > 50) return priceStart + 1;
  }
  return priceStart;
};

const localStyle = {
  marketBox: {flex: 1, display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 4},
  errorBox: {flex: 1, display: 'flex', flexDirection: 'row', backgroundColor: '#F66', padding: 4, alignItems: 'center', justifyContent: 'center', textAlign: 'center'},
  row: {display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'},
  table: {padding: 0},
  buttonRow: {display: 'flex', flexDirection: 'row', justifyContent: 'space-around', backgroundColor: '#FFF', padding: 4},
  bigFig: {flex: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', paddingRight: 4, fontSize: 25, fontWeight: 500},
  workingContainer: {fontSize: 25, fontWeight: 500, backgroundColor: 'yellow'},
  errorContainer: {fontSize: 18, fontWeight: 500, backgroundColor: '#F00', border: '1px solid #000', padding: 4, position: 'absolute', top: 0},
  minus: {display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontWeight: 500},
  column: {flex: 1},
  box: {flex: 1, textAlign: 'center', border: '1px solid #000', width: 60, fontWeight: 500, marginTop: 2},
  button: {flex: 1, textAlign: 'center', border: '1px solid #000', maxWidth: 120, minWidth: 120, height: 30, padding: 0, fontWeight: 600}
};
