
const defaultKey = 'wuHOIUHEWHWEUHHCUuhwuhiweuh';

export const encrypt = (data, key = defaultKey) => {
  const dataArray = data.split('').map((c, i) => c.charCodeAt(0) ^ key.charCodeAt(Math.floor(i % key.length)));
  const buff = Buffer.from(dataArray);
  return buff.toString('base64');
};

export const decrypt = (data, key = defaultKey) => {
  const buff = Buffer.from(data, 'base64');
  const dataArray = [...buff];
  return dataArray.map((c, i) => String.fromCharCode(c ^ key.charCodeAt(Math.floor(i % key.length)))).join('');
};
