import React from 'react';
import Blotter from './Blotter';
import Chat from './Chat';

import './blotter.css';

export default class BlotterBox extends React.Component {
  state = {}

  render () {
    return (
      <div className="blotterbox-outer">
        <Blotter/>
        <Chat/>
      </div>
    );
  }
}
