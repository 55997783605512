import React from 'react';
import {getData} from 'lib/data';
import {server} from 'lib/comms';
import {registerEvent, unRegisterEvent} from 'lib/eventlinks';

// const sounds = {
//   news: require('./sounds/m_news.mp3'),
//   deal: require('./sounds/deal.mp3')
// };

// export default ({sound, onDone, restart = false}) => {
//   return getData('mute', false) ? null : <Sound url={sounds[sound]} playStatus={Sound.status.PLAYING} playFromPosition={restart ? 0 : null} onFinishedPlaying={onDone}/>;
// };

export default class Sound extends React.Component {
  componentDidMount = () => {
    this.soundWatcher = registerEvent('sound', async ({sound, sounds, isBroker}) => {
      if (getData('mute', false)) return;
      if (sounds) {
        for (const sound of sounds) {
          if (getData('mute', false)) return;
          await this.playSoundWait(sound);
        }
      } else {
        const audioEl = document.getElementsByClassName('audio-element')[0];
        const audioElSource = document.getElementsByClassName('audio-element-source')[0];
        audioEl.pause();
        audioEl.currentTime = 0;
        audioElSource.src = `${server}/sounds/${sound}`; // https://assets.coderrocketfuel.com/pomodoro-times-up.mp3
        audioEl.load();
        audioEl.play().catch(e => e);
      }
    });
  }

  playSoundWait = (sound) => {
    return new Promise((resolve, reject) => {
      const audioEl = document.getElementsByClassName('audio-element')[1];
      const audioElSource = document.getElementsByClassName('audio-element-source')[1];
      audioEl.pause();
      audioEl.onended = () => setTimeout(resolve, 200);
      audioEl.currentTime = 0;
      audioElSource.src = `${server}/sounds/${sound}`;
      audioEl.load();
      audioEl.play().catch(reject);
    });
  }

  componentWillUnmount = () => unRegisterEvent(this.soundWatcher);

  render () {
    return (<>
      <audio className="audio-element">
        <source src="" className="audio-element-source"></source>
      </audio>
      <audio className="audio-element">
        <source src="" className="audio-element-source"></source>
      </audio>
    </>
    );
  }
}
