import React from 'react';
import './TimeValue.css';

export default ({title, value = '00:00:00', className = '', timeClassName = '', sliding = false}) => {
  if (sliding) timeClassName = 'sliding';
  return (
    <div className={`time-bar-value ${className}`}>
      <span>{title}</span>
      <span className={timeClassName}>{value}</span>
    </div>
  );
};
